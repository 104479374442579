<template>
  <div class="add-campaign">
    <SettingHeader> Campaign Setting </SettingHeader>
    <a-card>
      <template #title>
        <div class="form-status">
          Edit Campaign
          <span v-show="!isMobileScreen">:&nbsp;</span>
          <span v-show="!isMobileScreen">{{ campaignName }}</span>
        </div>
      </template>
      <template #extra>
        <div class="form-status">
          <div v-show="!isMobileScreen" class="form-row-title">Status:</div>
          <div class="form-row-input">
            <div class="d-flex align-items-center">
              <a-switch v-model:checked="statusCampaign" @change="onSwitch" />
              <span v-show="!isMobileScreen" class="text-status"> Active</span>
            </div>
          </div>
        </div>
      </template>
      <a-row :gutter="25">
        <a-col :xs="24" :sm="24" :md="24" class="pd-bottom">
          <span class="row-text"><FeatherIcon class="mr-5" type="file-text" /> Campaign Overview</span>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24">
          <span class="text-name">Campaign Name</span>
          <a-input v-model:value="campaignName" placeholder="Enter Campaign Name" disabled class="mr-top"></a-input>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" class="pd-bottom pd-top">
          <span class="row-text"
            ><FeatherIcon class="mr-5" type="key" /> Campaign Keywords
            <a-popover>
              <template #content>
                <div class="row-popover">
                  <p>Separate word, phase, hashtag or username with a comma or by pressing enter</p>
                </div>
              </template>
              <FeatherIcon class="mr-5 ml-5 text-gray info-cursor" type="info" />
            </a-popover>
          </span>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" class="pd-column">
          <a-col :xs="24" :sm="24" :md="24" class="pd-bottom frame-keywords">
            <div class="col-text-input">
              <img :src="require(`../../assets/images/icon/keyword.svg`)" />
              <span class="head-text-input">Keyword</span>
            </div>
            <div class="chip-container">
              <div v-for="(chip, i) of wordKeywordList" :key="chip.label" class="chip">
                {{ chip }}
                <FeatherIcon type="x" size="10" color="#5A5F7D" @click="onKeywordRemove(i)" />
              </div>
              <!-- <textarea
                v-model="currentKeywordInput"
                :placeholder="wordKeywordList.length === 0 ? 'Enter Keywords' : ''"
                :rows="10"
                class="keyword-textarea"
                @paste="onKeywordPasteChange"
                @keypress="onKeywordChange"
                @keydown.delete="backspaceKeywordDelete"
              ></textarea> -->
              <input
                v-model="currentKeywordInput"
                :placeholder="wordKeywordList.length === 0 ? 'Enter Keywords' : ''"
                class="keyword-textarea"
                @paste="onKeywordPasteChange"
                @keypress="onKeywordChange"
                @keydown.delete="backspaceKeywordDelete"
              />
            </div>
          </a-col>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12">
          <a-col :xs="24" :sm="24" :md="24" class="pd-bottom frame-exclude-keywords">
            <div class="col-text-input">
              <img :src="require(`../../assets/images/icon/exclude-keyword.svg`)" />
              <span class="head-text-input"><span class="color-text-exclude">Exclude</span> Keyword</span>
            </div>
            <div class="chip-container">
              <div v-for="(chip, i) of wordExcludeLists" :key="chip.label" class="chip">
                {{ chip }}
                <FeatherIcon type="x" size="10" color="#5A5F7D" @click="onExcludeRemove(i)" />
              </div>
              <!-- <textarea
                v-model="currentExcludeInput"
                :placeholder="wordExcludeLists.length === 0 ? 'Enter Exclude Keywords' : ''"
                :rows="10"
                class="keyword-textarea"
                @paste="onExcludePasteChange"
                @keypress="onExcludeChange"
                @keydown.delete="backspaceExcludeDelete"
              ></textarea> -->
              <input
                v-model="currentExcludeInput"
                :placeholder="wordExcludeLists.length === 0 ? 'Enter Exclude Keywords' : ''"
                class="keyword-textarea"
                @paste="onExcludePasteChange"
                @keypress="onExcludeChange"
                @keydown.delete="backspaceExcludeDelete"
              />
            </div>
          </a-col>
        </a-col>
      </a-row>

      <a-divider />
      <div class="text-right">
        <a-button
          :disabled="isDisabled"
          size="large"
          type="default"
          class="cancel-button mr-2"
          @click="cancelEditCampaign"
        >
          Cancel
        </a-button>
        <a-button :disabled="isSaveButtonDisable" size="large" :type="saveButtonType" class="confirm-button" @click="confirmEditCampaign">
          Save Changes
        </a-button>
      </div>
    </a-card>

    <a-modal v-model:visible="cancelUpdateVisible" class="modal-cancel-update-campaign" @ok="confirmCancel">
      <div class="icon-alert">
        <FeatherIcon class="floating-cancel-icon" type="info" :size="16" /><span class="alert-text"
          >Unsaved Changes</span
        >
      </div>
      <p class="alert-detail">Are you sure you want to leave this page without saving? All changes will be lost.</p>
      <template #footer>
        <a-button key="back" @click="cancelBtnCampaign">Cancel</a-button>
        <a-button key="submit" type="warning" @click="handleDiscardAdd">Discard</a-button>
      </template>
    </a-modal>

    <a-modal v-model:visible="updateSuccessVisible" class="modal-update-campaign" @ok="confirmAdd">
      <div class="icon-alert">
        <FeatherIcon class="floating-title-icon" type="check-circle" :size="16" /><span class="alert-text"
          >Success</span
        >
      </div>
      <p class="alert-detail">Your campaign has been updated successfully.</p>
      <template #footer>
        <a-button key="submit" type="success" @click="handleSuccessAdd">Ok</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import SettingHeader from '@/views/Settings/SettingHeader.vue';
import { ref, toRefs, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import api from '@/services/api';
import { generateKeywordsListForUpdateToAPI } from '@/services/campaign';
// import helper from '@/services/helper';
import { notification } from 'ant-design-vue';
export default {
  name: 'EditCampaign',
  props: {
    set: {
      type: Boolean,
      default: true,
    },
    color: String,
  },
  setup(props) {
    const { set, color } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const route = computed(() => useRoute());
    const loading = ref(true);
    let statusCampaign = ref(true);
    let storeStatusCampaign = ref('');
    let cancelUpdateVisible = ref(false);
    let updateSuccessVisible = ref(false);
    const keywordList = ref([]);
    const excludeKeywordList = ref([]);
    const wordKeywordList = ref([]);
    const wordExcludeLists = ref([]);
    let currentKeywordInput = ref('');
    let currentExcludeInput = ref('');
    let oldKeywordList = ref([]);
    let oldExcludeKeywordList = ref([]);
    let isDisabled = ref(true);
    let wordKeywordDeleteList = ref([]);
    let wordExcludeKeywordDeleteList = ref([]);
    let checkKeywordChange = ref('');
    let checkExcludeKeywordChange = ref('');
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);
    const campaignName = computed(() =>
      decodeURIComponent(route.value.params.id) ? decodeURIComponent(route.value.params.id).toLowerCase() : ''
    );
    const category = computed(() => store.getters['config/categoryData']);
    const cancelEditCampaign = () => {
      cancelUpdateVisible.value = true;
    };
    const role = computed(() => store.getters['account/userRole']);
    const isSaveButtonDisable = computed(() => {
      return ['accountmanager', 'user', 'feedonly'].includes(role.value);
    });
    const saveButtonType = computed(() => {
      if (isDisabled.value) {
        return 'disabled';
      } else {
        return ['accountmanager', 'user', 'feedonly'].includes(role.value) ? 'disabled' : 'primary';
      }
    });

    const getCampaignVisible = () => {
      category.value.map((campaign) => {
        if (campaignLevel.value === campaign.level) {
          campaign.categories.map((categoryName) => {
            if (campaignName.value === categoryName.category) {
              statusCampaign.value = categoryName.visible;
              storeStatusCampaign.value = categoryName.visible;
            }
          });
        }
      });
    };

    const cancelBtnCampaign = () => {
      cancelUpdateVisible.value = false;
    };

    const convertKeyword = (obj) => {
      const { id: keyword, createdAt, updatedAt, value } = obj;
      if (keyword.charAt(0) === '#') {
        return {
          originalKeyword: keyword,
          editingKeyword: keyword.slice(1),
          keyword: `#${keyword.slice(1)}`,
          type: 'hashtag',
          createdAt,
          updatedAt,
          category: campaignName.value,
          sentiment: value,
        };
      }
      if (keyword.indexOf('username:') === 0) {
        return {
          originalKeyword: keyword,
          keyword: `@${keyword.slice(9)}`,
          editingKeyword: `${keyword.slice(9)}`,
          type: 'username',
          createdAt,
          updatedAt,
          category: campaignName.value,
          sentiment: value,
        };
      }
      return {
        originalKeyword: keyword,
        keyword,
        type: 'word',
        createdAt,
        updatedAt,
        category: campaignName.value,
        sentiment: value,
      };
    };

    const getKeyword = async () => {
      loading.value = true;
      const result = await api.getCategoryKeywords(campaignLevel.value, campaignName.value).catch((e) => {
        console.log(e);
      });

      if (result && result.message && result.message.length !== 0) {
        oldKeywordList.value = [];
        const list = result.message.map((obj) => convertKeyword(obj));
        keywordList.value = list;
        wordKeywordList.value = keywordList.value.map((k) => {
          return k.originalKeyword;
        });

        if (result.message.length !== 0) {
          result.message.map((keyword) => {
            oldKeywordList.value.push(keyword.id);
          });
        } else {
          oldKeywordList.value = [];
        }
      }
      loading.value = false;
    };

    const getExcludeKeyword = async () => {
      loading.value = true;
      const result = await api.getCategoryExcludeKeywords(campaignLevel.value, campaignName.value).catch((e) => {
        console.log(e);
      });

      if (result && result.message && result.message.length !== 0) {
        oldExcludeKeywordList.value = [];
        const list = result.message.map((obj) => convertKeyword(obj));
        excludeKeywordList.value = list;
        wordExcludeLists.value = excludeKeywordList.value.map((ex) => {
          return ex.originalKeyword;
        });

        if (result.message.length !== 0) {
          result.message.map((keyword) => {
            oldExcludeKeywordList.value.push(keyword.id);
          });
        } else {
          oldExcludeKeywordList.value = [];
        }
      }
      loading.value = false;
    };

    const confirmEditCampaign = () => {
      asyncConfirmEditCampaign();
    };

    const asyncConfirmEditCampaign = async () => {
      await updateCampaignVisible();

      const [addedKeywords, deletedKeywords] = generateKeywordsListForUpdateToAPI(
        wordKeywordList.value,
        oldKeywordList.value
      );
      await updateKeyword(addedKeywords, deletedKeywords);

      const [addedExcludeKeywords, deletedExcludeKeywords] = generateKeywordsListForUpdateToAPI(
        wordExcludeLists.value,
        oldExcludeKeywordList.value
      );
      await updateExcludeKeyword(addedExcludeKeywords, deletedExcludeKeywords);
      updateSuccessVisible.value = true;
    };

    const updateCampaignVisible = async () => {
      try {
        await api.editCategory(campaignLevel.value, campaignName.value, color.value, statusCampaign.value);
      } catch (e) {
        console.log(e);
      }
    };

    const updateKeyword = async (addedKeywords, deletedKeywords) => {
      console.log('addedKeywords', addedKeywords);
      console.log('deletedKeywords', deletedKeywords);
      if (addedKeywords && addedKeywords.length > 0) {
        await addKeyword(campaignLevel.value, campaignName.value, addedKeywords);
      }
      if (deletedKeywords && deletedKeywords.length > 0) {
        await deleteKeyword(campaignLevel.value, campaignName.value, deletedKeywords);
      }
    };

    const updateExcludeKeyword = async (addedKeywords, deletedKeywords) => {
      console.log('addedKeywords', addedKeywords);
      console.log('deletedKeywords', deletedKeywords);
      if (addedKeywords && addedKeywords.length > 0) {
        await addExcludeKeyword(campaignLevel.value, campaignName.value, addedKeywords);
      }
      if (deletedKeywords && deletedKeywords.length > 0) {
        await deleteExcludeKeyword(campaignLevel.value, campaignName.value, deletedKeywords);
      }
    };

    const addKeyword = async (campaignLevel, campaignName, lists) => {
      try {
        await api.bulkCreateCategoryKeyword(campaignLevel, campaignName, lists);
      } catch (e) {
        console.error(e);
        let description = 'There was an error. Your campaign has been updated unsuccessfully.';
        if (e.response.status === 409) {
          description = 'Campaign ' + `${campaignName}` + 'keyword is duplicate.';
        }
        notification.error({
          message: 'Error',
          description,
        });
      }
    };

    const deleteKeyword = async (campaignLevel, campaignName, list) => {
      try {
        await api.bulkDeleteCategoryKeyword(campaignLevel, campaignName, list);
      } catch (e) {
        console.error(e);
        notification.error({
          message: 'Error',
          description: 'There was an error. Your campaign has been updated unsuccessfully.',
        });
      }
    };

    const addExcludeKeyword = async (campaignLevel, campaignName, lists) => {
      console.log('addExcludeKeyword lists ', lists);
      try {
        await api.bulkCreateCategoryExcludeKeyword(campaignLevel, campaignName, lists);
      } catch (e) {
        console.error(e);
        let description = 'There was an error. Your campaign has been updated unsuccessfully.';
        if (e.response.status === 409) {
          description = 'Campaign ' + `${campaignName}` + 'exclude keyword is duplicate.';
        }
        notification.error({
          message: 'Error',
          description,
        });
      }
    };

    const deleteExcludeKeyword = async (campaignLevel, campaignName, list) => {
      try {
        await api.bulkDeleteCategoryExcludeKeyword(campaignLevel, campaignName, list);
      } catch (e) {
        console.error(e);
        notification.error({
          message: 'Error',
          description: 'There was an error. Your campaign has been updated unsuccessfully.',
        });
      }
    };

    const confirmAdd = () => {
      updateSuccessVisible.value = false;
    };

    const toggleCloseModal = (state) => {
      cancelUpdateVisible.value = state;
    };

    const onKeywordChange = () => {
      const key = window.event.keyCode;
      let value = currentKeywordInput.value.trim();
      let checkKeywordOverlap;
      if (key === 13 || key === 32) {
        if (value.length > 0) {
          ((set && wordKeywordList.value.indexOf(value) === -1) || !set) && wordKeywordList.value.push(value);
          value = '';
          currentKeywordInput.value = value.trim();
          checkKeywordOverlap = wordKeywordList.value.filter(function (v) {
            return wordKeywordDeleteList.value.indexOf(v) > -1;
          });

          checkKeywordChange.value = wordKeywordList.value.filter(function (obj) {
            return oldKeywordList.value.indexOf(obj) === -1;
          });
          wordKeywordDeleteList.value.splice(checkKeywordOverlap);
        }
      }
    };

    const onKeywordRemove = async (index) => {
      wordKeywordDeleteList.value.push(wordKeywordList.value[index]);
      wordKeywordList.value.splice(index, 1);
    };

    const backspaceKeywordDelete = ({ which }) => {
      if (wordKeywordList.value[wordKeywordList.value.length - 1] !== undefined) {
        wordKeywordDeleteList.value.push(wordKeywordList.value[wordKeywordList.value.length - 1]);
      }
      which == 8 && currentKeywordInput.value === '' && wordKeywordList.value.splice(wordKeywordList.value.length - 1);
    };

    const onExcludeChange = () => {
      const key = window.event.keyCode;
      let value = currentExcludeInput.value.trim();
      let checkExcludeKeywordOverlap;
      if (key === 13 || key === 32) {
        if (value.length > 0) {
          ((set && wordExcludeLists.value.indexOf(value) === -1) || !set) && wordExcludeLists.value.push(value);
          value = '';
          currentExcludeInput.value = value.trim();
          checkExcludeKeywordOverlap = wordExcludeLists.value.filter(function (v) {
            return wordExcludeKeywordDeleteList.value.indexOf(v) > -1;
          });
          checkExcludeKeywordChange.value = wordExcludeLists.value.filter(function (obj) {
            return oldExcludeKeywordList.value.indexOf(obj) === -1;
          });
          wordExcludeKeywordDeleteList.value.splice(checkExcludeKeywordOverlap);
        }
      }
    };

    const onExcludeRemove = async (index) => {
      wordExcludeKeywordDeleteList.value.push(wordExcludeLists.value[index]);
      wordExcludeLists.value.splice(index, 1);
    };

    const backspaceExcludeDelete = async ({ which }) => {
      if (wordExcludeLists.value[wordExcludeLists.value.length - 1] !== undefined) {
        wordExcludeKeywordDeleteList.value.push(wordExcludeLists.value[wordExcludeLists.value.length - 1]);
      }
      which == 8 &&
        currentExcludeInput.value === '' &&
        wordExcludeLists.value.splice(wordExcludeLists.value.length - 1);
    };

    const onKeywordPasteChange = (e) => {
      e.preventDefault();
      let pastedText = '';
      let checkKeywordOverlap;
      if (e.clipboardData && e.clipboardData.getData) {
        pastedText = e.clipboardData.getData('text/plain');
      } else if (window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData('Text');
      }
      for (let value of pastedText.split(',')) {
        if (value.length > 0) {
          ((set && wordKeywordList.value.indexOf(value.trim()) === -1) || !set) &&
            wordKeywordList.value.push(value.trim());
          value = '';
          value = '';
          currentKeywordInput.value = value.trim();
          checkKeywordOverlap = wordKeywordList.value.filter(function (v) {
            return wordKeywordDeleteList.value.indexOf(v) > -1;
          });

          checkKeywordChange.value = wordKeywordList.value.filter(function (obj) {
            return oldKeywordList.value.indexOf(obj) === -1;
          });
          wordKeywordDeleteList.value.splice(checkKeywordOverlap);
        }
      }
    };

    const onExcludePasteChange = (e) => {
      e.preventDefault();
      let pastedText = '';
      let checkExcludeKeywordOverlap;
      if (e.clipboardData && e.clipboardData.getData) {
        pastedText = e.clipboardData.getData('text/plain');
      } else if (window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData('Text');
      }
      for (let value of pastedText.split(',')) {
        if (value.length > 0) {
          ((set && wordExcludeLists.value.indexOf(value.trim()) === -1) || !set) &&
            wordExcludeLists.value.push(value.trim());
          value = '';
          currentExcludeInput.value = value.trim();
          checkExcludeKeywordOverlap = wordExcludeLists.value.filter(function (v) {
            return wordExcludeKeywordDeleteList.value.indexOf(v) > -1;
          });
          checkExcludeKeywordChange.value = wordExcludeLists.value.filter(function (obj) {
            return oldExcludeKeywordList.value.indexOf(obj) === -1;
          });
          wordExcludeKeywordDeleteList.value.splice(checkExcludeKeywordOverlap);
        }
      }
    };

    const handleDiscardAdd = () => {
      cancelUpdateVisible.value = false;
      router.push(`/campaign/${encodeURIComponent(campaignName.value)}`);
    };

    const handleSuccessAdd = () => {
      updateSuccessVisible.value = false;
      router.push(`/campaign/${encodeURIComponent(campaignName.value)}`);
    };

    const onSwitch = (e) => {
      if (storeStatusCampaign.value !== e) {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
    };

    const checkDuplicateKeyword = wordKeywordList.value.filter(function (obj) {
      return oldKeywordList.value.indexOf(obj) === -1;
    });

    const checkDuplicateExcludeKeyword = wordExcludeLists.value.filter(function (obj) {
      return oldExcludeKeywordList.value.indexOf(obj) === -1;
    });

    watch(() => {
      if (
        checkDuplicateKeyword.length > 0 ||
        checkDuplicateExcludeKeyword.length > 0 ||
        wordKeywordDeleteList.value.length > 0 ||
        wordExcludeKeywordDeleteList.value.length > 0 ||
        checkKeywordChange.value.length > 0 ||
        checkExcludeKeywordChange.value.length > 0
      ) {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
    });

    onMounted(() => {
      getKeyword();
      getExcludeKeyword();
      getCampaignVisible();
    });

    return {
      statusCampaign,
      cancelEditCampaign,
      confirmEditCampaign,
      cancelUpdateVisible,
      updateSuccessVisible,
      toggleCloseModal,
      cancelBtnCampaign,
      onKeywordChange,
      wordKeywordList,
      wordExcludeLists,
      currentKeywordInput,
      currentExcludeInput,
      onKeywordRemove,
      backspaceKeywordDelete,
      onExcludeChange,
      onExcludeRemove,
      backspaceExcludeDelete,
      campaignName,
      confirmAdd,
      handleDiscardAdd,
      handleSuccessAdd,
      getCampaignVisible,
      updateCampaignVisible,
      isMobileScreen,
      isDisabled,
      role,
      saveButtonType,
      isSaveButtonDisable,
      onSwitch,
      onKeywordPasteChange,
      onExcludePasteChange,
    };
  },
  components: {
    SettingHeader,
  },
};
</script>

<style lang="scss">
.floating-error-icon {
  color: red;
}
.btn-text-white {
  color: white;
}
.ant-card-head-title span {
  font-size: 16px;
  margin-left: unset;
  text-transform: capitalize;
  color: #3371ff;
}

textarea {
  resize: none;
}
.ant-popover-placement-top > .ant-popover-content:has(div > div > div > div.row-popover) > .ant-popover-arrow {
  border-right-color: #272b41;
  border-bottom-color: #272b41;
}
.ant-popover-inner:has(div > div > div.row-popover) {
  border-radius: 2px;
  background-color: #272b41;
}
.ant-popover-inner-content {
  padding: 0px;
}

.info-cursor {
  cursor: pointer;
}
.ant-modal-content {
  padding: 20px;
}
.ant-modal-body {
  border: none;
  .floating-title-icon {
    color: #20c977;
  }

  .floating-cancel-icon {
    color: #fa8b0c;
  }
}

.modal-add-campaign .ant-modal-content .ant-modal-footer {
  border: none;
  div {
    .ant-btn:first-child {
      display: none;
    }
    .ant-btn-primary {
      background: #20c997;
      padding: 12px 32px;
    }
  }
}

.chip-container {
  height: 100%;
  min-height: 250px;
  overflow: auto;
  border: 1px solid #e3e6ef;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background: #fff;
  padding: 12px 11px;
  border-radius: 4px;
  .chip {
    font-family: 'DM Sans', 'IBM Plex Sans Thai', sans-serif;
    height: 20px;
    margin: 0 4px 10px 7px;
    background: #eff0f3;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #5a5f7d;
    i {
      cursor: pointer;
      margin-left: 4px;
      line-height: 14px !important;
    }
  }
  .keyword-textarea {
    height: 20px;
    padding: 0;
    flex: 1 1 auto;
    border: none;
    outline: none;
    white-space: normal;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #9299b8;
    }
  }
}

.ant-modal-close-x {
  display: none;
}

.ant-modal-footer {
  border-top: unset;
}

.icon-alert {
  display: flex;
  align-items: center;
}

.alert-text {
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}

.alert-detail {
  color: #5a5f7d;
  margin-left: 25px;
}
.add-campaign {
  padding: 30px;
  overflow: auto;
  height: 100%;
  width: 100%;
}
.form-status {
  display: flex;
  align-content: center;
}
.form-row-title {
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  margin-right: 5px;
}
.text-status {
  font-size: 13px;
  font-weight: 400;
  color: #5a5f7d;
  margin-left: 5px;
}

.text-name {
  font-weight: 500;
  color: #272b41;
}

.row-text {
  font-size: 16px;
  font-weight: 500;
  color: #3371ff;
  display: flex;
  align-items: center;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.text-gray {
  color: #9299b8;
}

.mr-top {
  margin-top: 10px;
}

.pd-bottom {
  padding-bottom: 25px;
}

.pd-top {
  padding-top: 25px;
}

.row-popover {
  width: 250px;
  height: 78px;
  color: #fff;
  background-color: #272b41;
  padding: 6px 8px 6px 8px;
}

.ant-switch-checked.ant-switch {
  background-color: #20c997;
}

.frame-keywords {
  border: 1px solid #e0eaff;
  background-color: #f5f8ff;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
  //   margin: 20px;
  border-radius: 8px;
}

.frame-exclude-keywords {
  border: 1px solid #feeedb;
  background-color: #fff9f3;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
  //   margin: 20px;
  border-radius: 8px;
}

.head-text-input {
  font-size: 16px;
  font-weight: 500;
  color: #272b41;
  margin-left: 10px;
}
.col-text-input {
  margin-bottom: 20px;
  display: flex;
}

.frame-keywords .ant-input:hover,
.frame-exclude-keywords .ant-input:hover {
  border: 1px solid #e3e6ef;
}

.cancel-button {
  background-color: #f1f2f6;
  font-size: 14px;
}
.confirm-button {
  font-size: 14px;
}

.color-text-exclude {
  color: #fa8b0c;
}

@media only screen and (max-width: 768px) {
  .frame-keywords,
  .frame-exclude-keywords {
    margin: unset;
  }

  .form-status {
    align-items: center;
  }
  .pd-column {
    padding-bottom: 12.5px;
  }
}

@media only screen and (max-width: 575px) {
  .ant-card-head-wrapper {
    flex-flow: unset;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding-top: 20px !important;
  }

  .ant-card-head-title > div {
    flex-flow: unset;
  }

  .ant-card-head-title > div span {
    margin: 0px 0 0;
  }
}
</style>
