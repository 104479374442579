import helper from '@/services/helper';

export function generateKeywordsListForUpdateToAPI(currentState, originalState) {
  /** Elements added to the original state to form the current state */
  let addedElements = currentState.filter((element) => !originalState.includes(element));
  addedElements = helper.prepareKeywordList({
    mode: 'word',
    list: addedElements,
    sentimentValue: 0,
  });

  /** Elements removed from the original state to form the current state */
  const deletedElements = originalState.filter((element) => !currentState.includes(element));

  return [addedElements.map(mutateKeywordForApiBody), deletedElements];
}

/** internal utils */
function mutateKeywordForApiBody(word) {
  return { word, value: 0 };
}
